<template>
  <ul :id="containerId">
    <li v-for="item in visibleItems" :class="itemClass" :key="item.id">
      <slot :item="item" :item-renderer="itemRenderer">No content for the slot.</slot>
    </li>
    <li v-if="items.length === 0" :class="itemClass"><i>{{ x('noMoreElements') }}</i></li>
  </ul>
</template>

<script>
  import { take } from 'lodash';
  import { guidNoDash } from '@/classes/guid';
  import translate from './Mixins/Translate';

  export default {
    name: 'progressive-list',
    props: {
      // All items must have an id property, to be used as :key.
      items: {
        type: Array,
      },
      itemRenderer: {
        type: Function,
      },
      // How many items are added to the display when the user scrolls
      batchSize: {
        type: Number,
        default: 50,
      },
      // How many are shown on initial render, i.e. before any scrolling.
      initialBatchSize: {
        type: Number,
        default: 50,
      },
    },
    mixins: [translate],
    data() {
      const containerId = guidNoDash();
      return {
        containerId,
        containerElement: null, // this needs to be set later,
        // when the DOM is around. So it is lazy loaded.
        displayCount: this.initialBatchSize,
        hasScroll: false,
      };
    },
    computed: {
      itemClass() {
        return this.$store.state.config.mode === 'app' ? 'Theme_C1_BaseColor' : 'Theme_C1_TXT';
      },
      visibleItems() {
        return take(this.items, this.displayCount);
      },
    },
    watch: {
      items() {
        this.checkForScroll();
      },
    },
    mounted() {
      this.checkForScroll();
    },
    methods: {
      checkForScroll() {
        if (this.items.length > this.initialBatchSize && this.hasScroll === false) {
          this.hasScroll = true;
          this.getContainerElement().scroll(() => {
            this.handleScroll();
          });
        }
      },
      handleScroll() {
        const elem = this.getContainerElement();
        const distanceFromBottom = elem.prop('scrollHeight') - (elem.scrollTop() + elem.height());
        // console.log(distanceFromBottom);
        if (distanceFromBottom < 750) {
          this.displayCount += this.batchSize;
        }
      },
      getContainerElement() {
        if (this.containerElement !== null) {
          return this.containerElement;
        }
        const elem = $(`#${this.containerId}`);
        if (elem.length > 0) {
          this.containerElement = elem;
          return elem;
        }
        return null;
      },
    },
  };
</script>
