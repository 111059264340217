<template>
  <div :class="'filter_'+type" class="filterbox">
    <b :class="config.mode === 'admin' ? 'Theme_C1_Headline' : 'ThemeAppFilterGroupName'">
      <a @click="toggleList()">
        <i class='fa fa-plus' :class="config.mode === 'admin' ? '' : 'ThemeAppFilterGroupName'"></i>
      </a> {{ x(label) }} {{ modified }}
    </b>
    <div v-show="ui.showList">
      <input type="text" :placeholder="config.translation['searchDots']"
             class="ThemeAppFilterGroupName"
             :id="'filter_' + type + '_search'" v-model="searchString">
      <div class="listContainer">
        <div class="waitingSheet Theme_C1_BaseColor" v-if="ui.isLoading">
          <i class="fa fa-cog fa-spin"></i>
          {{ x('retrievingDataDots') }}
        </div>
        <progressive-list v-if="items !== null" :items="filteredItems"
                          :item-renderer="itemRenderer">
          <template v-slot="itemData">
            <input type='checkbox' :value='itemData.item.id' v-model="selectedItems">
            {{itemData.itemRenderer(itemData.item)}}
          </template>
        </progressive-list>
        <div v-if="items === null" style="height:50px"></div>
      </div>
      <div v-if="showInactiveToggle" class="toggleInactive">
        {{ x(showInactiveItems ? 'showingInactiveItems' : 'notShowingInactiveItems') }}
        <button class="lsc-button lsc-rounded-10 green toggleButton" @click="toggleInactive()">
          {{ x(showInactiveItems ? 'hide' : 'show') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { isEmpty } from 'lodash';
  import { mapState } from 'vuex';
  import { httpGet } from '@/classes/httpHelper';
  import ComponentEventNames from '@/enums/component-event-names';
  import ProgressiveList from '../ProgressiveList';
  import translate from '../Mixins/Translate';

  export default {
    name: 'list-filter-item',
    mixins: [translate],
    components: {
      'progressive-list': ProgressiveList,
    },
    props: {
      type: {
        // The type of data that this component will work on.
        type: String,
        required: true,
      },
      itemRenderer: {
        // A function that is used to render the item. It should return a string.
        type: Function,
        required: true,
      },
      modelValue: {
        // The items that are selected in this component.
        type: Array,
        required: true,
      },
      dependency: {
        // If this ListFilterItem should be dependent on the selection done in another
        // ListFilterItem, this prop should reference the data of the other component.
        type: Array,
        required: false,
      },
      subset: {
        // This specifies the actual filter items in the subset, if one is used.
        type: Array,
        required: false,
      },
      subsetQuery: {
        // If this ListFilterItem is dependent on another data source, this should be a function
        // that will return the data for this list depending on the data provided in the subset
        // property.
        type: Function,
        required: false,
      },
      manualLabel: {
        // The label of this component is the type translated. If a manual word should be used,
        // provide it here.
        type: String,
        required: false,
      },
      manualDataSource: {
        // If the data of the list should be provided manually, provide it in this property.
        // Either as a string array, or as an array of { id, name }-objects.
        type: Array,
        required: false,
      },
      showInactiveToggle: {
        // Flag that indicates if a button to toggle the visibility of inactive items should
        // be shown.
        type: Boolean,
        default: false,
      },
      customActiveFilter: {
        // If inactive toggle is enabled and special functionality is needed to determine whether
        // or not an item is "active", it can be provided here.
        // The function should take a list of all items as parameter and return a list of
        // the active ones. Active items are expected to be a subset of the active + inactive items.
        type: Function,
        required: false,
      },
    },
    emits: [ComponentEventNames.updateModelValue],
    data() {
      return {
        ui: {
          showList: false,
          isLoading: false,
        },
        items: null,
        filteredItems: null,
        selectedItems: [],
        timeoutId: null,
        searchString: '',
        searchTimeoutId: null,
        showInactiveItems: false,
        cache: [],
      };
    },
    computed: {
      label() {
        return this.manualLabel || this.type;
      },
      modified() {
        return this.selectedItems.length > 0 ? '*' : '';
      },
      ...mapState(['config', 'user']),
    },
    watch: {
      selectedItems() {
        this.$emit(ComponentEventNames.updateModelValue, this.selectedItems);
      },
      async subset() {
        if (!this.config.obj_texts.EnableDependentFilters) return;
        // We throttle the number of times we re-query the subset.
        // If multiple changes happen within 1,5 seconds, they are bundled.
        clearTimeout(this.timeoutId);
        const self = this;
        this.timeoutId = setTimeout(async () => {
          await self.queryData();
        }, 1500);
      },
      items() {
        console.log('Items list was updated.');
        this.filterList();
      },
      searchString() {
        clearTimeout(this.searchTimerId);
        setTimeout(() => {
          this.filterList();
        }, 1500);
      },
      modelValue(newValue) {
        // This ensures that the selected items are set at the right time.
        this.selectedItems = newValue;
      },
    },
    methods: {
      async toggleList() {
        this.ui.showList = !this.ui.showList;
        if (this.ui.showList) {
          if (this.items === null) {
            try {
              await this.queryData();
              console.log('Retrieved data: ', this.items);
            } catch (e) {
              console.error(`Error while retrieving ${this.type} list.`);
              console.error(e);
            }
          }
        }
      },
      async queryData() {
        if (!isEmpty(this.manualDataSource)) {
          this.items = this.prepareData(this.manualDataSource);
        } else {
          this.ui.isLoading = true;
          if (this.subsetQuery !== undefined
            && this.subset !== undefined
            && this.subset.length > 0) {
            this.items = await (this.subsetQuery(this.subset));
          } else {
            // If we are not using subset querying, we can cache the full dataset.
            if (this.cache.length === 0) {
              if (this.showInactiveToggle) {
                console.log(`Loading ${this.type} into cache, including inactive items.`);
                this.cache = await httpGet(`${this.type}?includeInactive=true`);
              } else {
                console.log(`Loading ${this.type} into cache.`);
                this.cache = await httpGet(this.type);
              }
            }

            // Now we know that we have the full dataset...
            if (this.showInactiveToggle === true && this.showInactiveItems === false) {
              console.log('customActiveFilter: ', this.customActiveFilter);
              if (this.customActiveFilter) {
                console.log('Using custom active filter');
                this.items = this.customActiveFilter(this.cache);
              } else {
                console.log('Not using custom active filter, just becuz');
                this.items = this.cache.filter((item) => isEmpty(item.inactiveDate));
              }
            } else {
              this.items = this.cache;
            }
          }
          this.ui.isLoading = false;
        }
      },
      filterList() {
        if (this.searchString === '') {
          this.filteredItems = this.items;
        } else {
          this.filteredItems = this.items.filter((item) => this.itemRenderer(item)
            .toLowerCase()
            .indexOf(this.searchString.toLowerCase()) > -1);
        }
      },
      prepareData(list) {
        return list.map((item) => {
          if (typeof item === 'string') {
            return { name: item, id: item };
          }
          return item;
        });
      },
      toggleInactive() {
        this.showInactiveItems = !this.showInactiveItems;
        this.queryData();
      },
    },
  };
</script>

<style>
  .toggleInactive {
    font-style: italic;
    margin-left: 10px;
    margin-top: 15px;
  }

  .toggleInactive .toggleButton {
    float: right;
    width: auto !important;
    margin: -5px 10px 0 0 !important;
    font-size: 8px;
    font-weight: 700;
    padding: 5px 7px;
  }
</style>
